import React, { useState, useEffect } from "react";
import "./modalAddPoints.css";
import { Button, Select, InputNumber } from "antd";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { GreenNotify } from "../../helper/helper";

const ModalAddPoints = ({
  setShowModal,
  setIsLoading,
  products,
}) => {
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [users, setUsers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [reward, setReward] = useState(0);
  const { Option } = Select;

  const handleChallengeSelect = async (value) => {
    const selectedChallengeObj = JSON.parse(value);
    setSelectedChallenge(selectedChallengeObj);
    setSelectedPlayer(null);
    const matchedUsers = users.filter(user => selectedChallengeObj.players.includes(user._id));
    setPlayers(matchedUsers);
  };

  const handlePlayerSelect = (value) => {
    const selectedPlayerObj = JSON.parse(value);
    setSelectedPlayer(selectedPlayerObj);
  };

  const addPoints = () => {
    let getRes = (res) => {
        GreenNotify("Points have been given successfully");
        setShowModal(false);
    };

    let body = {
      userInfo: selectedPlayer,
      challengeId: selectedChallenge._id,
      reward: reward,
    };

    callApi("POST", routes.addPoints, body, setIsLoading, getRes, (error) => console.log("error", error));
  };

const getUseer = () => {
    let getRes = (res) => {
        setUsers(res?.data?.data);
        console.log("res of get all users", res);
    };

    callApi("GET", routes.getAllUsers, null, setIsLoading, getRes, (error) => console.log("error", error));
  };

  useEffect(() => {
    getUseer();
  }, []);

  const handleAddButtonClick = () => {
    addPoints();
  };

  const isFormIncomplete = () => {
    return (
      !selectedChallenge ||
      !reward ||
      !selectedPlayer
    );
  };

  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "2rem" }}></div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>All Challenges</h2>
          <Select
          value={selectedChallenge?.title}
          style={{ width: "100%" }}
          onChange={handleChallengeSelect}
          placeholder="Please select a challenge"
        >
          {products.map((challenge) => (
            ((challenge?.players?.length > 0) && (challenge.players !== null)) && <Option key={challenge._id} value={JSON.stringify(challenge)}>
              {challenge.title}
            </Option>
          ))}
        </Select>
        </div>

        {players && (
        <div style={{ marginBottom: "2rem" }} className="add-product-modal-input-title">
          <h2>All Players</h2>
          <Select
            value={selectedPlayer?.name}
            style={{ width: "100%" }}
            onChange={handlePlayerSelect}
            placeholder="Please select a player"
          >
            {/* Map over the players of the selected challenge */}
            {
              players.map((player) => (
                <Option key={player.id} value={JSON.stringify(player)}>
                  {player.name}
                </Option>
              ))}
          </Select>
        </div>
      )}

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Reward</h2>
          <InputNumber
            value={reward}
            onChange={(e) => setReward(e)}
            min={0}
            placeholder="0"
          />
        </div>

        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>

            <Button
              onClick={handleAddButtonClick}
              style={{ marginLeft: "2rem" }}
              type="primary"
              disabled={isFormIncomplete()}
            >
              Give Reward
            </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddPoints;
