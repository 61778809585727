import React, { useState, useRef } from "react";
import "./modalAddChallenge.css";
import {
  Button,
  Modal,
  Select,
  Input,
  InputNumber,
  Checkbox,
  Image,
} from "antd";
// import { Select } from 'antd';

import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { upload } from "../../helper/helper";
import DatePicker from "react-datepicker";
const ModalAddChallenge = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  // const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [challengeType, setChallengeType] = useState(
    addProduct ? "" : item?.challengeType
  );
  // const [status, setStatus] = useState(addProduct ? '' : item?.status);
  const [reward, setReward] = useState(addProduct ? 0 : item?.reward);
  const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const { Option } = Select;

  // const [duration, setDuration] = useState(addProduct ? 0 : item?.duration);
  const fileInputRef = useRef(null);

  const [requiredCriteria, setRequiredCriteria] = useState(
    addProduct ? 0 : item?.requiredCriteria
  );
  const [image, setImage] = useState(addProduct ? "" : item?.image);
  // const onChange = (e) => {
  //   setIsSale(e.target.checked);
  //   console.log(`Checkbox checked: ${e.target.checked}`);
  // };

  const pickImageFile = () => {
    fileInputRef.current.click();
  };

  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  //console.log("add product", addProduct);

  const handleFileChange = (event) => {
    const fileList = event.target.files;

    // Perform operations with the selected file(s)
    console.log(fileList);
  };

  const handleAddButtonClick = () => {
    // Add your logic to handle the button click here
    createProduct(); // Call the function to add the challenge
  };

  const isFormIncomplete = () => {
    return (
      !challengeType ||
      !reward ||
      !requiredCriteria ||
      !startDate ||
      !endDate ||
      !image
    );
  };

  const createProduct = () => {
    let getRes = (res) => {
      console.log("res of create product", res);
      setShowModal(false);
      setAddProduct(false);
    };

    // const dateString = startDate
    // const unixTimestamp = Date.parse(dateString) / 1000;

    const dateStringStart = new Date(startDate);
    const dateStringEnd = new Date(endDate);

    // console.log(1111,dateString);
    // //     const timestamp = startDate;
    // const hoursToRemove = 5;
    // const secondsInAnHour = 3600;
    // const secondsToRemove = hoursToRemove * secondsInAnHour;

    // const newTimestamp = unixTimestamp - secondsToRemove;

    let body = {
      challengeType: challengeType,
      title: title,
      image: image,
      reward: reward,
      startDate: dateStringStart,
      endDate: dateStringEnd,
      requiredCriteria: requiredCriteria,
    };

    callApi(
      "POST",
      routes.createChallenge,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const updateProduct = () => {
    let getRes = (res) => {
      // console.log("res of update product", res);
      setShowModal(false);
    };

    let body = {
      challengeType: challengeType,
      title: title,
      image: image,
      reward: reward,
      // startDate:startDate,
      // endDate:endDate,
      requiredCriteria: requiredCriteria,
    };

    callApi(
      "PATCH",
      `${routes.updateChallenge}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  //   console.log("item", title1);

  let checkStartDate = (e) => {
    // console.log("my time",new Date(e.target.value))
    // console.log("local time",new Date())
    if (new Date(e.target.value) > new Date()) {
      setStartDate(e.target.value);
    } else {
      alert("PLease select future date");
    }
  };
  let checkEndDate = (e) => {
    const selectedEndDate = new Date(e.target.value);
    const selectedStartDate = new Date(startDate);

    // console.log("Start Date:", selectedStartDate);
    // console.log("End Date:", selectedEndDate);

    if (selectedEndDate > selectedStartDate) {
      setEndDate(e.target.value);
    } else {
      alert("Enter end date is not earlier than start date");
    }
  };

  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "2rem" }}></div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Challenge Type</h2>
          <Select
            value={challengeType}
            // defaultValue="game-play"
            style={{ width: "100%" }}
            onChange={(value) => setChallengeType(value)}
            placeholder="Please select a challenge type"
          >
            <Option value="game-play" selected>
              Game Play
            </Option>
            {/* <Option value="friend-invite">Friend Invite</Option> */}
          </Select>
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
           <h2>Title</h2>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="description"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Reward</h2>
          <InputNumber
            value={reward}
            onChange={(e) => setReward(e)}
            min={0}
            // placeholder="0"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Required Criteria</h2>
          <InputNumber
            value={requiredCriteria}
            onChange={(e) => setRequiredCriteria(e)}
            min={0}
            // placeholder="0"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Start Date</h2>
          <Input
            // type='date'
            value={startDate}
            type="datetime-local"
            // onchange="convertDate(event)"
            onChange={(e) => checkStartDate(e)}
          />
        </div>
        {/* placeholder="Mon Jul 10 2023 07:19:51 GMT-0400" */}
        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>End Date</h2>
          <Input
            type="datetime-local"
            value={endDate}
            onChange={(e) => checkEndDate(e)}
            // placeholder="Mon Jul 10 2023 07:19:51 GMT-0400"
          />
        </div>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input
          onChange={upload((url) => setImage(url), setIsLoading)}
        />
        <div
          onClick={pickImageFile}
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Image</h2>
          <div className="add-product-modal-image">
            <img src={image ? image : dummyImage} alt="" />
          </div>
        </div>
        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={handleAddButtonClick}
              style={{ marginLeft: "2rem" }}
              type="primary"
              disabled={isFormIncomplete()}
            >
              Add Challenge
            </Button>
          ) : (
            <Button
              onClick={updateProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default ModalAddChallenge;
