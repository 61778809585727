import React, { useState, useRef } from "react";
import "./modalAddUser.css";
import { Button, Modal, Input, InputNumber, Checkbox, Image } from "antd";
import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { upload } from "../../helper/helper";
import DatePicker from "react-datepicker";
const UpdateUser = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  // const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [reward, setReward] = useState(addProduct ? 0 : item?.rewards);

  const fileInputRef = useRef(null);

  const pickImageFile = () => {
    fileInputRef.current.click();
  };

  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  //console.log("add product", addProduct);

  const handleFileChange = (event) => {
    const fileList = event.target.files;

    // Perform operations with the selected file(s)
    console.log(fileList);
  };



  const createProduct = () => {
    let getRes = (res) => {
      console.log("res of create product", res);
      setShowModal(false);
      setAddProduct(false);
    };

    let body = {
      rewards: reward,
    };
    
    callApi(
      "POST",
      routes.signUp,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
      );
    };
    
    const updateProduct = () => {
      let getRes = (res) => {
        // console.log("res of update product", res);
        setShowModal(false);
      };
      
      let body = {
        rewards: reward,
    };

    callApi(
      "PATCH",
      `${routes.updateUser}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  //   console.log("item", title1);
  
  
  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "2rem" }}></div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Reward</h2>
          <InputNumber
            value={reward}
            onChange={(e) => setReward(e)}
            // min={0}
            // placeholder="0"
          />
        </div>

        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={createProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Add Product
            </Button>
          ) : (
            <Button
              onClick={updateProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default UpdateUser;
