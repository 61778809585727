export { default as homeIcon } from "./home.png";
export { default as productIcon } from "./product.png";
export { default as searchIcon } from "./Search.png";
export { default as addIcon } from "./add-circle.png";
export { default as redTrash } from "./trash.png";
export { default as editIcon } from "./edit-icon.png";
export { default as serviceIcon } from "./service.png";
export { default as options } from "./options.png";
export { default as userIcon } from "./user.png";
export { default as crossIcon } from "./cross.png";
export { default as trueIcon } from "./true.png";
export { default as productOrder } from "./product-order.png";
export { default as serviceOrder } from "./oderlist.png";
export { default as challenge } from "./challenge.png";
export { default as game } from "./game.png";
export { default as gameIcon } from "./gameIcon.png";
export { default as amountIcon } from "./amountIcon.png";
export { default as postIcon } from "./postIcon.png";
export { default as rewardRefundIcon } from "./rewardRefundIcon.png";
