import React, { useState, useRef, useEffect } from "react";
import "./modalAddGame.css";
import { Button, Modal, Input, Select, InputNumber, Checkbox, Image } from "antd";
import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { callApi } from "../../api/apiCaller";
import { Buffer } from "buffer";
import routes from "../../api/routes";
import { RedNotify, upload } from "../../helper/helper";
const AWS = require("aws-sdk");
AWS.config.update({
  accessKeyId: "AKIAVTRDW7SYZDI52F2K",
  secretAccessKey: "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",
  region: "us-east-2",
});
const S3 = new AWS.S3();
const uploadImageOnS3 = async (src, cb) => {
  return new Promise((resolve, reject) => {
    try {
      console.log("Hi!");
      const reader = new FileReader();
      reader.onload = async () => {
        console.log("Hiello!");
        // cb(reader.result)
        const params = {
          Bucket: "drivebuddyz",
          Key: `${10000 + Math.round(Math.random() * 10000)}.png`,
          Body: new Buffer(
            reader.result.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          ),
        };
        let res = await S3.upload(params).promise();
        console.log(res);
        return resolve(res.Location);
      };
      reader.onerror = (e) => console.log("OOPS!", e);
      reader.readAsDataURL(src);
    } catch (error) {
      console.error("Error uploading to S3:", error);
      reject(error);
    }
  });
};

const ModalAddGame = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  const [placeName, setPlaceName] = useState(addProduct ? "" : item?.placename);
  // const [status, setStatus] = useState(addProduct ? "" : item?.status);
  const [description, setDescription] = useState(
    addProduct ? "" : item?.description
  );
  const [playTimeHr, setPlayTimeHr] = useState(
    addProduct ? 1 : item?.playtimehr
  );
  const [playTimeMin, setPlayTimeMin] = useState(
    addProduct ? 1 : item?.playtimemin
  );
  const [gameType, setGameType] = useState(addProduct ? "" : item?.gametype);
  const [availableSlots, setAvailableSlots] = useState(
    addProduct ? 1 : item?.availableslots
  );
  const [xp, setXp] = useState(addProduct ? 1 : item?.xp);
  const [price, setPrice] = useState(addProduct ? 1 : item?.price);
  // const [purchasexp, setPurchasexp] = useState(
  //   addProduct ? 0 : item?.purchasexp
  // );
  const fileInputRef = useRef(null);
  const [dateandtime, setDateandtime] = useState(new Date());
  const [image, setImage] = useState([]);
  const [location, setLocation] = useState(addProduct ? "" : "");
  const [checkLocation, setCheckLocation] = useState(addProduct);
  const { Option } = Select;


  useEffect(() => {
    console.log("setImage1", image);
  }, [image]);

  const pickImageFile = () => {
    fileInputRef.current.click();
  };
  // console.log("imageee", image);

  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  //console.log("add product", addProduct);

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    // Perform operations with the selected file(s)
    console.log(fileList);
  };

  const createProduct = async () => {
    console.log("imageData", image);
   
    var newImage = await Promise.all(
      [...image].map(async (item, index) => {
        console.log("All Images", item);
        const uploadImage = await uploadImageOnS3(item);
        console.log("uploadimage", uploadImage);
        return uploadImage;
      })
    );
      if(!placeName){
      alert("Please put place name");
      return;
    }
      if(!description){
      alert("Please put description");
      return;
    }
    if ( newImage.length<1) {
      alert("Please add at least one image");
      return;
    }
    if(!gameType){
      alert("Please put game type");
      return;
    }
    if (location =='') {
      alert("Please add google map location link");
      return;
    }
  //   if(!playTimeHr){
  //   alert("Please put play time hour");
  //   return;
  // }
    if(!playTimeMin){
      alert("Please put play time minute");
      return;
    }
    if(!availableSlots){
      alert("Please put available slots");
      return;
    }
    if(!price){
      alert("Please put price");
      return;
    }
    if(!xp){
      alert("Please put xp");
      return;
    }
    const aa = new Date(dateandtime)
    if (aa < new Date()) {
      alert("Please select future date and time");
      return;
    } 

    
    let imageData = newImage.map((link) => ({
      link: link,
      mediaType: link.includes(".mp4") ? "video" : "image",
    }));
    let getRes = (res) => {
      console.log("res of create product", res);
      setShowModal(false);
      setAddProduct(false);
    };

    const splitted = location?.split("/");
    const address = splitted[5];
    const format = address?.split("+");
    const formattedLocation = format?.join(", ");
    const ab = formattedLocation?.split(", ");
    const joinedString = ab?.join(" ");
    const coordinates = splitted?.[6]?.split("@")[1].split(",");

    if (!(coordinates?.[0] || coordinates?.[1])) {
      return RedNotify("Invalid Location URL. URL Must have long and lat!")
    }

    const locationData = {
      coordinates: [coordinates?.[1], coordinates?.[0]],
      address: formattedLocation,
    };

    const dateString = new Date(dateandtime).toString()
    const parts = dateString?.split(" ");
    // console.log('value', parts);
    const timeZoneName = parts?.slice(0,6).join(" ").toString()
    // console.log('value1',timeZoneName);


    let body = {
      placename: placeName,
      description: description,
      playtimehr: playTimeHr,
      playtimemin: playTimeMin,
      gametype: gameType,
      availableslots: availableSlots,
      xp: xp,
      location: locationData,
      media: imageData,
      // status: status,
      price: price,
      // purchasexp: purchasexp,
      dateandtime: dateandtime,
    };

    callApi("POST", routes.createGame, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const updateProduct = async () => {
    const newImage = await Promise.all(
      [...image].map(async (item, index) => {
        const uploadImage = await uploadImageOnS3(item);
        return uploadImage;
      })
    );
    let getRes = (res) => {
      // console.log("res of update product", res);
      setShowModal(false);
    };

    let imageData = newImage.map((link) => ({
      link: link,
      mediaType: link.includes(".mp4") ? "video" : "image",
    }));

if(imageData.length==0){
  let body = {
    placename: placeName,
    description: description,
    playtimehr: playTimeHr,
    playtimemin: playTimeMin,
    gametype: gameType,
    availableslots: availableSlots,
    xp: xp,
    // media: imageData,
    // status: status,
    price: price,
    // purchasexp: purchasexp,
    // dateandtime: dateandtime,
  };

  callApi(
    "PATCH",
    `${routes.updateGame}/${item?._id}`,
    body,
    setIsLoading,
    getRes,
    (error) => {
      console.log("error", error);
    }
  );
}else{
  let body = {
    placename: placeName,
    description: description,
    playtimehr: playTimeHr,
    playtimemin: playTimeMin,
    gametype: gameType,
    availableslots: availableSlots,
    xp: xp,
    media: imageData,
    price: price,
    // status: status,
    // purchasexp: purchasexp,
    // dateandtime: dateandtime,
  };

  callApi(
    "PATCH",
    `${routes.updateGame}/${item?._id}`,
    body,
    setIsLoading,
    getRes,
    (error) => {
      console.log("error", error);
    }
  );
}
    
  };


  let checkStartDate = (e) => {
    // console.log("my time",new Date(e.target.value))
    // console.log("local time",new Date())
    if(new Date(e.target.value)>new Date()){
      setDateandtime(e.target.value)
    }else{
      alert("PLease select future date")
    }
  }
  //   console.log("item", title1);
  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "37rem" }}></div>
        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Place Name</h2>
          <Input
            value={placeName}
            placeholder="placeName"
            onChange={(e) => {
              setPlaceName(e.target.value);
            }}
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Description</h2>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="description"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Media</h2>
          <Input
            // value={""}
            type="file"
            multiple="multiple"
            // onChange={(e) => console.log("aaaaa", e.target.files)}
            // onChange={(e) => setImage(handleInputChange)}
            onChange={(e) => setImage(e.target.files)}
            placeholder="media"
          />
        </div>

        {/* <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>GameType</h2>
          <Input
            value={gameType}
            onChange={(e) => setGameType(e.target.value)}
            placeholder="gameType"
          />
        </div> */}

                <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >

<h2>GameType</h2>
  <Select value={gameType} 
  defaultValue="game-play"
  style={{width:'100%'}}  onChange={(value) => setGameType(value)} placeholder="Please select a game type">
    <Option value="Indoor" selected>Indoor</Option>
    <Option value="Outdoor">Outdoor</Option>
  </Select>
</div>

  {checkLocation ? (<div
    style={{ marginBottom: "2rem" }}
    className="add-product-modal-input-title"
  >
    <h2>Location</h2>
    <Input
      value={location}
      onChange={(e) => {
        setLocation(e.target.value);
      }}
      placeholder="Add map location link"
    />
  </div>):''}

        {/* <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >

<h2>Status</h2>
  <Select value={status} 
  // defaultValue="game-play"
  style={{width:'100%'}}  onChange={(value) => setStatus(value)} placeholder="Please select a challenge type">
    <Option value="Upcoming" selected>Upcoming</Option>
    <Option value="Completed">Completed</Option>
    <Option value="Cancelled">Cancelled</Option>
  </Select>
</div> */}
          
          {/* <h2>Status</h2>
          <Input
            value={status}
            onChange={(e) => setStatus(e.target?.value)}
            placeholder='Please enter only "Upcoming", "Completed" or "Cancelled"'
          />
        </div> */}

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Play Time Hour</h2>
          <InputNumber
            value={playTimeHr}
            onChange={(e) => setPlayTimeHr(e)}
            min={0}
            max={59}
            // placeholder="playTimeHr"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Play Time Minute</h2>
          <InputNumber
            value={playTimeMin}
            onChange={(e) => setPlayTimeMin(e)}
            min={0}
            max={59}
            // placeholder="setPlayTimeMin"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Available Slots</h2>
          <InputNumber
            value={availableSlots}
            onChange={(e) => setAvailableSlots(e)}
            min={1}
            // placeholder="0"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Price</h2>
          <InputNumber
            value={price}
            onChange={(e) => setPrice(e)}
            min={1}
            // placeholder="0"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2> XP</h2>
          <div className="add-product-modal-amount-container">
            <InputNumber
              value={xp}
              onChange={(e) => {
                setXp(e);
              }}
              // prefix="$"
              min={1}
              // placeholder="0"
            />
          </div>
        </div>

        <div 
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Date And Time</h2>
          <Input
            value={dateandtime}
            type="datetime-local"
            onChange={(e) => checkStartDate(e)}
            // placeholder="Mon Jul 10 2023 07:19:51 GMT-0400"
          />
        </div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          {/* <Checkbox onChange={onChange}>Is Sale?</Checkbox> */}
        </div>

        {/* <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the file input
          onChange={upload((url) => setImage(url), setIsLoading)}
        />
        <div
          onClick={pickImageFile}
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Image</h2>
          <div className="add-product-modal-image">
            <img src={image ? image : dummyImage} alt="" />
          </div>
        </div> */}
        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={createProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Add Game
            </Button>
          ) : (
            <Button
              onClick={updateProduct}
              style={{ marginLeft: "2rem" }}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default ModalAddGame;
