import "./landingPage.css";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import Loader from "../../components/loader/loader";
// import {
//   homeIcon,
//   orderIcon1,
//   productIcon,
//   productIcon1,
//   redTrash,
//   serviceIcon,
//   serviceIcon1,
//   userIcon1,
// } from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { useMemo } from "react";


const LandingPage = () => {
  const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [totalUsers,setTotalUsers] = useState('');
  const [amounttotalbooking,setAmounttotalbooking] = useState('');
  const [amountrefund,setAmountrefund] = useState('');
  const [rewardrefund,setRewardrefund] = useState('');
  const [post,setPost] = useState('');
  const [game,setGame] = useState('');
  const getAllUserData = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      console.log("res of user list length", res?.rewardrefund);
      setTotalUsers(res?.users)
      setAmounttotalbooking(res?.amounttotalbooking)
      setAmountrefund(res?.amountrefund)
      setRewardrefund(res?.rewardrefund)
      setPost(res?.posts)
      setGame(res?.games)
      // setUsers(res?.user);
      // chekDetails(res?.data)
    };    
    callApi("GET", routes.getStats, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };


  useMemo(() => {
    getAllUserData();
    // getAllProductsorServices();
    // getAllProductAndServicesOrders();
  }, [totalUsers,amounttotalbooking,amountrefund,rewardrefund,post,game]);



  const stateArr = [
    {
      title: "Total User",
      count: `${totalUsers}`,
      // icon: userIcon1,
    },
    {
      title: "Total Booking Amount",
      count: `${amounttotalbooking}`,
      // icon: productIcon1,
    },
    {
      title: "Amount Refund",
      count: `${amountrefund}`,
      // icon: serviceIcon1,
    },
    {
      title: "Reward Refund",
      count: `${rewardrefund}`,
      // icon: orderIcon1,
    },
    {
      title: "Post",
      count: `${post}`,
      // icon: orderIcon1,
    },
    {
      title: "Game",
      count: `${game}`,
      // icon: orderIcon1,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="admin-products-main-container">
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          {/* <img src={homeIcon} alt="home-icon" /> */}
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Landing page</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dynamic-card-main-container">
        <div className="dynamic-card"></div>
      </div>
    </div>
  );
};

export default LandingPage;
