
// export const BASE_URL = "http://localhost:4700/api/v1";
export const BASE_URL = "https://raxgv5jbru.us-east-2.awsapprunner.com/api/v1";


export default {
  // -----AUTH------//
  signUp: BASE_URL + "/user/signup",
  signIn: BASE_URL + "/user/testLogin",
  sendOTP: BASE_URL + "/user/sendOTP",
  verifyOTP: BASE_URL + "/user/verify",
  forgotPassword: BASE_URL + "/user/forgotPassword",
  resetPassword: BASE_URL + "/user/resetPassword",
  verifyOTPresetPassword: BASE_URL + "/user/verifyOTPResetPassword",
  logOut: BASE_URL + "/user/logout",
  updateUser: BASE_URL + "/user",
  deleteMe: BASE_URL + "/user/deleteMe",
  getStats: BASE_URL + "/user/stats",
  getAllUsers: BASE_URL + "/user",

  // -----------Products--------------//

  getProducts: BASE_URL + "/product/getAll?limit=1000",
  upDateProduct: BASE_URL + "/product/update",
  createProduct: BASE_URL + "/product/create",
  deleteProduct: BASE_URL + "/product/delete",
  allBookedProduct: BASE_URL + "/booking/allbookedproducts",
  // -------------Services-------------//

  getAllServices: BASE_URL + "/service/getall",
  createService: BASE_URL + "/service/create",
  deleteService: BASE_URL + "/service/delete",
  updateService: BASE_URL + "/service/update",
  allBookedServices: BASE_URL + "/booking/allbookedservices",
  // -------------Challenges-------------//

  getAllChallenges: BASE_URL + "/challenge/getall",
  createChallenge: BASE_URL + "/challenge/create",
  deleteChallenge: BASE_URL + "/challenge/delete",
  updateChallenge: BASE_URL + "/challenge/update",
  updateChallenge: BASE_URL + "/challenge/update",
  addPoints: BASE_URL + "/challenge/addpoint",
  // -------------Challenges-------------//

  getAllGames: BASE_URL + "/game/getall",
  createGame: BASE_URL + "/game/create",
  deleteGame: BASE_URL + "/game/delete",
  updateGame: BASE_URL + "/game/update",

  // -------------Services-------------//
  getAllUser: BASE_URL + "/user",
};
