import React, { useEffect, useState } from "react";
import "./stats.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import Loader from "../../components/loader/loader";
import {
//   homeIcon,
//   orderIcon1,
//   productIcon,
//   productIcon1,
//   redTrash,
//   serviceIcon,
//   serviceIcon1,
gameIcon,
postIcon,
amountIcon,
  userIcon,
  rewardRefundIcon
} from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { useMemo } from "react";


const Dashboard = () => {
  const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [totalUsers,setTotalUsers] = useState('');
  const [amounttotalbooking,setAmounttotalbooking] = useState('');
  const [amountrefund,setAmountrefund] = useState('');
  const [rewardrefund,setRewardrefund] = useState('');
  const [post,setPost] = useState('');
  const [game,setGame] = useState('');
  const getAllUserData = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      console.log("res of user list length", res?.users);
      setTotalUsers(res?.users)
      setAmounttotalbooking(res?.amounttotalbooking)
      setAmountrefund(res?.amountrefund)
      setRewardrefund(res?.rewardrefund)
      setPost(res?.posts)
      setGame(res?.games)
      // setUsers(res?.data?.user);
      // chekDetails(res?.data?.data)
    };    
    callApi("GET", routes.getStats, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };


  useMemo(() => {
    getAllUserData();
    // getAllProductsorServices();
    // getAllProductAndServicesOrders();
  }, [totalUsers,amounttotalbooking,amountrefund,rewardrefund,post,game]);



  const stateArr = [
    {
      title: "Total User",
      count: `${totalUsers}`,
      icon: userIcon,
    },
    {
      title: "Total Booking Amount",
      count: `${amounttotalbooking}`,
      icon: amountIcon,
    },
    {
      title: "Amount Refund",
      count: `${amountrefund}`,
      icon: amountIcon,
    },
    {
      title: "Reward Refund",
      count: `${rewardrefund}`,
      icon: rewardRefundIcon,
    },
    {
      title: "Post",
      count: `${post}`,
      icon: postIcon,
    },
    {
      title: "Game",
      count: `${game}`,
      icon: gameIcon,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="admin-products-main-container">
     <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          {/* <img src={homeIcon} alt="home-icon" /> */}
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-main-container">
        <div className="dashboard-state-container">
          {stateArr.map((item) => (
            <div className="dashboard-state-count-container">
              <div className="dashboard-state-icon">
                <img src={item.icon} alt="icon" />
              </div>
              <div className="dashboard-state">
                <h2>{item.title}</h2>
                <p>{item.count}</p>
              </div>
            </div>
          ))}

          {/* <div className="dashboard-state-count-container"></div> */}
        </div>
        <div className="dashboard-pie-chart-container">
          <Clock size={120} value={value} />
          <p>
            Current time:{" "}
            <span style={{ color: "red", fontWeight: "700" }}>
              {moment(new Date()).format("DD, MMM, YYYY , HH:mm A")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;